<template>
    <b-row>
        <b-col cols="4">
            <b-card
                border-variant="info"
                align="center"
                class="zero_padding_card_body bg-light"
            >
                <b-card-text>
                    <b-row>
                        <b-col class="text-right" cols="6">
                            {{ $t('profit_and_loss.label.credit_netto') }}:
                        </b-col>
                        <b-col class="text-left text-success" cols="6">
                            {{ result.credit_amount_netto | priceFormat }} &euro;
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right" cols="6">
                            {{ $t('profit_and_loss.label.credit_vat') }}:
                        </b-col>
                        <b-col class="text-left text-success" cols="6">
                            {{ result.credit_amount_vat | priceFormat }} &euro;
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right" cols="6">
                            {{ $t('profit_and_loss.label.debit_netto') }}:
                        </b-col>
                        <b-col class="text-left text-danger" cols="6">
                            {{ result.debit_amount_netto | priceFormat }} &euro;
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right" cols="6">
                            {{ $t('profit_and_loss.label.debit_vat') }}:
                        </b-col>
                        <b-col class="text-left text-danger" cols="6">
                            {{ result.debit_amount_vat | priceFormat }} &euro;
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right" cols="6">
                            {{ $t('profit_and_loss.label.cash_netto') }}:
                        </b-col>
                        <b-col class="text-left text-danger" cols="6">
                            {{ result.debit_cash_netto | priceFormat }} &euro;
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right" cols="6">
                            {{ $t('profit_and_loss.label.cash_vat') }}:
                        </b-col>
                        <b-col class="text-left text-danger" cols="6">
                            {{ result.debit_cash_vat | priceFormat }} &euro;
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col cols="6">
            <CChartPie
                v-if="!loading"
                :datasets="debitCreditData"
                :labels="['Credit', 'Credit VAT', 'Debit', 'Debit VAT', 'Cash', 'Cash VAT']"
            />
        </b-col>
    </b-row>
</template>

<script>
import {CChartPie} from '@coreui/vue-chartjs'
import {mapGetters} from "vuex";

export default {
    name: 'ProfitAndLossGeneral',
    components: {CChartPie},
    props: {
        'month': [String],
    },
    data() {
        return {
            loading: true,
            debitCreditData: null,
            result: {},
        }
    },
    methods: {
        ...mapGetters('ProfitAndLoss', ['getGeneral']),
        shown() {
            this.loading = true
            const generalFetch = this.$store.dispatch('ProfitAndLoss/fetchGeneral', {month: this.month});

            Promise.all([generalFetch])
                .then((result) => {
                    this.result =result[0].data

                    let values = []
                    values.push(parseFloat(result[0].data.credit_amount_netto))
                    values.push(parseFloat(result[0].data.credit_amount_vat))
                    values.push(parseFloat(result[0].data.debit_amount_netto))
                    values.push(parseFloat(result[0].data.debit_amount_vat))
                    values.push(parseFloat(result[0].data.debit_cash_netto))
                    values.push(parseFloat(result[0].data.debit_cash_vat))
                    this.debitCreditData = [
                        {
                            backgroundColor: [
                                '#41B883',
                                '#41B840',
                                '#E46651',
                                '#E46601',
                                '#E44101',
                                '#E44991',
                            ],
                            data: values
                        }
                    ]

                    this.loading = false
                });
        },
    },
    mounted() {
        this.shown()
    },
    watch: {
        month: function (val) {
            this.shown()
        },
    }
}
</script>